<template>
  <div class="result">
    <!-- 基本信息 -->
    <div class="left border">
      <div class="left_com">
        <!-- <i class="el-icon-office-building left_icon"></i> -->
        <div class="left_icon"></div>
        <div class="left_company_name">{{ mainName }}</div>
        <div class="left_txt" @click="changeCompany">[重新匹配]</div>
      </div>
      <div class="left_project">
        <div class="project_img">
          <!-- <div class="img_num" v-if="showVipCom">
            293
          </div> -->
          <div class="img_num">
            {{ labels.length > 0 && labels[0].total !== null ? labels[0].total : 0 }}
          </div>
          <div class="img_unit">可申报项目数</div>
        </div>
        <div class="img_tip">匹配结果基于以下信息分析得出，仅供参考</div>
      </div>
      <div
        v-for="(item, index) in basicDatas"
        :key="index"
        class="left_basic"
      >
        <div class="basic_header" @click="() => item.show = !item.show">
        <!-- <div class="basic_header" :style="{ backgroundColor: (show ? 'rgba(48, 120, 240, 0.05)' : '#fff') }"> -->
          <div class="basic_left">
            <i
              :class="[ item.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right']"
            ></i>
            {{item.title}}
          </div>
          <div class="basic_right">
            <i
              :class="map[item.status].icon"
              :style="{ color: map[item.status].color }"
            ></i>
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="item.show">
            <div
              v-for="(sub, ind) in item.children"
              :key="ind"
              class="basic_content"
            >
              <div class="content">
                <div>{{sub.title}}</div>
                <div
                  class="content_txt"
                  :style="{ color: map[sub.status].color }"
                >
                  <span
                    v-if="sub.status === 1"
                    class="content_txt_btn"
                    @click="handleBasicClick"
                  >
                    {{sub.content}}
                  </span>
                  <span v-else>{{sub.content}}</span>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <div class="left_btn">
        <button
          :class="[showStars === 5 ? 'btn_done' : 'btn_doing', 'btn']"
          @click="editData"
        >
          {{ showStars === 5 ? '修改信息' : '完善信息' }}
        </button>
      </div>
      <div class="left_bottom">
        <div class="bottom_header">
          <div class="rate_txt">当前完善度</div>
          <el-rate
            v-model="showStars"
            disabled
          >
          </el-rate>
        </div>
        <div>信息越完善，匹配越精准</div>
      </div>
    </div>
    <!-- 匹配结果 -->
    <div class="right border" style="position: relative;">
      <!-- <vipPermission v-if="showVipCom" :vipPermissionVisible="true" :type="2"></vipPermission> -->
      <div class="right_header">
        <div class="header_top">
          <div class="header_top_left">
            <div class="top_left_status">
              <!-- <i class="el-icon-document-checked"></i> -->
              <div class="top_left_status_icon"></div>
              匹配成功
            </div>
            <!-- <div class="top_left_tip">
              <div class="opportunity"></div>
              如果想要更精准的匹配结果，可使用
              <span
                style="color: #156ED0;cursor: pointer;"
                @click="goToMatch"
              >
                [专业版]
              </span>
              项目匹配
            </div> -->
          </div>
          <div
            v-if="list.length > 0"
            class="header_top_right"
            @click="exportRes"
          >
            <i
              class="el-icon-download"
            >
            </i>
            导出结果
          </div>
        </div>
        <div
          class="header_label"
        >
          <div
            v-for="(item, index) in labels"
            :key="index"
            :class="['label_btn', item.show ? 'btn_active' : 'btn_inactive']"
            @click="handleLabel(index, item)"
          >
            {{`${item.name}(${item.total !== null ? item.total : 0})`}}
          </div>
        </div>
      </div>
      <div
        class="right_content right_content_supple"
      >
        <div
          v-for="(item, index) in list"
          :key="index"
          class="content_item"
        >
          <div class="item_left">
            <div
              class="item_title"
              @click="clickResultItem(item)"
            >
              {{item.subtitle}}
            </div>
            <div class="item_label">
              <div
                :class="['label_day', item.remainingTime === -1 ? 'label_day_close' : 'label_day_open']"
              >
                {{item.remainingTime === -1 ? '已结束' : `距申报截止${item.remainingTime}天`}}
              </div>
              <div
                v-for="(type, ind) in item.support"
                :key="ind"
                class="label_type"
                :style="{ color: typeMap[type].color, backgroundColor: typeMap[type].bgColor }"
              >
                {{type}}
              </div>
              <div
                class="label_level"
              >
                {{item.policyLevel}}
              </div>
            </div>
            <div class="item_bottom">
              <span
                class="bottom_left"
              >
                发文日期：{{item.dispatchDate}}
              </span>
              <span>发文部门：{{item.department}}</span>
            </div>
          </div>
          <div class="item_right">
            <!-- <div
              :class="[
                'item_btn',
                item.hasPlan === null ? 'left_btn_active' : 'left_btn_inactive'
              ]"
              @click="handleHasPlan(item)"
            >
              {{item.hasPlan === null ? '纳入计划': '已纳入'}}
            </div> -->
            <!-- <div
              :class="[
                'item_btn',
                item.hasDeclare === null ? 'right_btn_active' : 'right_btn_inactive'
              ]"
              @click="handleHasDeclare(item)"
            >
              {{item.hasDeclare === null ? '我要申报' : '申报中'}}
            </div> -->
            <div class="item_btn right_btn_active" @click="handleHasDeclare(item)">我要申报</div>
          </div>
        </div>
        <div
          v-if="list.length <= 0"
          class="item_empty"
        >
          <div class="empty_img"></div>
          <div v-if="labels[0].total > 0">
            <div class="empty_txt">暂无项目</div>
          </div>
          <div v-else>
            <div class="empty_txt">没有匹配到项目</div>
            <div class="empty_txt">您可尝试完善企业信息后再次匹配</div>
            <div class="empty_btn" @click="editData">完善信息</div>
          </div>
        </div>
      </div>
      <div
        v-if="totalSize > 10"
        class="right_page"
      >
        <div class="page_box">
          <el-button
            :disabled="projectSearchParam.pageNo === 1"
            @click="prevPage"
          >
            上一页
          </el-button>
          <div class="page_num">
            <span
              class="current_page"
            >
              {{ `${projectSearchParam.pageNo}` }}
            </span>
            <span>
              {{ `/${Math.ceil(totalSize / 10)}` }}
            </span>
          </div>
          <el-button
            :disabled="projectSearchParam.pageNo === Math.ceil(totalSize / 10)"
            @click="nextPage"
          >
            下一页
          </el-button>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :show-close="componentName !== 'vipPermission'"
      :visible.sync="dialogVisible"
      @close="closeDialog"
      :width="dialogWidth"
      destroy-on-close
    >
      <div v-if="dialogVisible" slot="title" class="header">
        <div v-if="dialogType === '资质认证'">
          <span class="header_title">{{dialogConfig.title}}</span>
          <span class="header_num">共{{certification.length}}项</span>
        </div>
        <div v-else-if="dialogType === '编辑信息'">
          <span class="header_title">完善企业信息</span>
        </div>
        <div v-else-if="dialogType === '行业政策线标签'">
          <div v-if="progressBar[curTagPage - 1] < 1" class="header_label_title">
            <span class="header_label_title_left">
              行业政策线标签
            </span>
            <span class="header_label_title_right">
              勾选符合您企业的标签
            </span>
          </div>
          <div v-else class="header_label_title">
            <span class="header_label_title_left">
              数据已完善
            </span>
          </div>
          <div class="header_label_progress">
            <div class="progress_txt">进度</div>
            <div class="progress">
              <el-progress :percentage="currentProgressBar"></el-progress>
            </div>
            <div v-if="currentProgressBar === 100" class="progress_success"></div>
          </div>
        </div>
        <div v-else-if="dialogType === '我要申报'">
          <span class="header_title">我要申报</span>
        </div>
      </div>
      <component
        v-if="dialogVisible"
        :is="componentName"
        :config="dialogConfig"
        @close="closeDialog"
        @goToPerfect="goToPerfect"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import { getProjectPage, addHasPlan } from '@/api/simple_matching/index';
import { getMemberId } from '@/assets/public/utils/token';
import { mapState, mapMutations } from 'vuex';
import ShowData from './component/showData.vue';
import EditData from './component/editData.vue';
import CheckLabel from './component/checkLabel.vue';
import hasDeclare from './component/hasDeclare.vue';

export default {
  components: {
    ShowData,
    EditData,
    CheckLabel,
    hasDeclare
  },
  data () {
    return {
      status: 1,
      radio: '',
      show: true,
      currentPage: 1,
      totalPage: 5,
      percentage: 0,
      projectSearchParam: {
        typeId: '',
        userId: null,
        pageNo: 1,
        pageSize: 10
      },
      map: {
        1: { icon: 'el-icon-check', color: '#156ED0' },
        2: { icon: 'el-icon-question', color: '#F7B320' },
        3: { icon: '', color: 'rgba(0, 0, 0, .5)' }
      },
      basicDatas: [
        {
          title: '基本情况',
          show: false,
          status: 1,
          children: [
            { title: '注册地址', content: '广东省 / 佛山市 / 南海区桂城街道', status: 3, field: 'addressTxt' },
            { title: '企业类型', content: '有限责任公司', status: 3, field: 'companyOrgTypeTxt' },
            { title: '企业规模', content: '小型企业', status: 3, field: 'enterpriseGroupTxt' },
            { title: '所属行业', content: ' 信息传输、软件和信息技术服务业', status: 3, field: 'categoryTxt' },
            { title: '学历', content: '博士/硕士/本科/专科', status: 3, field: 'educationTxt' },
            { title: '职称', content: '高级职称/中级职称', status: 3, field: 'competentTxt' }
          ]
        },
        {
          title: '经营概况',
          show: false,
          status: 1,
          children: [
            { title: '上市', content: '无', status: 3, field: '' },
            { title: '产学研合作', content: '无', status: 3, field: '' },
            { title: '参展参会', content: '有', status: 3, field: '' }
          ]
        },
        {
          title: '知识产权',
          show: false,
          status: 2,
          children: [
            { title: '实用新型专利', content: '未知', status: 2, field: '' },
            { title: '发明专利', content: '未知', status: 2, field: '' },
            { title: '外观设计专利', content: '3项', status: 3, field: '' },
            { title: '软件著作权', content: ' 未知', status: 2, field: '' },
            { title: '作品著作权', content: '3项', status: 3, field: '' },
            { title: '作品著作权', content: '5项', status: 3, field: '' }
          ]
        },
        {
          title: '资质认证',
          show: false,
          status: 1,
          children: [
            { title: '0项', content: '查看', status: 1, field: '' }
          ]
        }
      ],
      labels: [
        { typeId: 1, name: '全部', total: 0, show: true },
        { typeId: 2, name: '资金扶持', total: 0, show: false },
        { typeId: 3, name: '称号认定', total: 0, show: false },
        { typeId: 4, name: '减税降费', total: 0, show: false },
        { typeId: 5, name: '股权投资', total: 0, show: false },
        { typeId: 6, name: '金融扶持', total: 0, show: false }
      ],
      copyList: [],
      list: [],
      // {
      //     title: '高新技术企业认定补助（2022市级）',
      //     day: 13,
      //     types: ['资金扶持'],
      //     level: '国家级',
      //     time: '2022-06-21',
      //     company: '广东省工业和信息化部',
      //     status: 0
      //   },
      typeMap: {
        资金扶持: { color: 'rgba(255, 114, 0, 1)', bgColor: 'rgba(255, 114, 0, 0.1)' },
        称号认定: { color: 'rgba(31, 170, 83, 1)', bgColor: 'rgba(31, 170, 83, 0.05)' },
        减税降费: { color: 'rgba(191, 56, 47, 1)', bgColor: 'rgba(191, 56, 47, 0.05)' },
        股权投资: { color: 'rgba(31, 136, 249, 1)', bgColor: 'rgba(31, 136, 249, 0.05)' },
        金融扶持: { color: 'rgba(108, 194, 237, 1)', bgColor: 'rgba(108, 194, 237, 0.05)' },
        其他: { color: 'rgba(255, 255, 255,1)', bgColor: 'rgba(0, 0, 0, 0.4)'}
      },

      dialogVisible: false,
      componentName: '',
      dialogConfig: {},
      dialogType: '',
      dialogWidth: '',
      totalSize: 0,
      certification: [],
      mainName: JSON.parse(localStorage.getItem("mainName")),
    };
  },
  computed: {
    ...mapState('simpleMatching', [
      'companyName', 'projectNum', 'stars', 'recordId',
      'basicData', 'categoryTotal', 'progressBar', 'curTagPage',
      'baseURL'
    ]),
    ...mapState({
      isVip: (state) => state.login.isVip
    }),
    showVipCom () {
      return this.isVip === '普通用户';
    },
    showStars () {
      if(this.showVipCom) return 3;
      else return this.stars;
    },
    userId () {
      return getMemberId();
    },
    currentProgressBar () {
      return this.progressBar[this.curTagPage - 1] ? Math.round(this.progressBar[this.curTagPage - 1] * 100) : 0;
    }
  },
  mounted () {
    this.getBasicData();
    this.projectSearchParam.userId = this.userId;
    this.getProjectData();
    this.getCategoryTotal();
  },
  methods: {
    ...mapMutations('simpleMatching', [ 'resetTagGroupLists', 'setCategoryTotal', 'setStars', 'resetProCurTag' ]),
    exportRes () {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      const saveLink = document.createElement('a');
      // 1：专业版，2：简易版
      saveLink.href = this.baseURL + '/pcp/projectmatch/exportResult?recordId=' + this.recordId + '&matchEdition=' + 2;
      saveLink.click();
    },
    goToMatch () {
      // this.$confirm('开发中，敬请期待', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'success'
      // })
      this.$router.push('/matching');
    },
    clickResultItem (item) {
      const routeData = this.$router.resolve({
        path: '/formaldetails/jiedu',
        query: {
          id: item.policyId,
          inputId: item.projectId
        }
      });
      window.open(routeData.href, '_blank');
    },
    prevPage () {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      this.projectSearchParam.pageNo -= 1;
      this.getProjectData();
    },
    nextPage () {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      this.projectSearchParam.pageNo += 1;
      this.getProjectData();
    },
    handleHasPlan (item) {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      addHasPlan({
        memberId: this.userId,
        formalId: item.projectId,
        type: item.hasPlan !== null ? '1' : '0'
      }).then(res => {
        this.$message({
          message: item.hasPlan !== null ? '取消纳入成功' : '纳入计划成功',
          type: 'success'
        });
        this.getProjectData();
      });
    },
    handleHasDeclare (item) {
      console.log(item)
      this.dialogVisible = true;
      this.componentName = 'hasDeclare';
      this.dialogType = '我要申报';
      this.dialogWidth = '480px';
      this.dialogConfig = {
        policyId: item.policyId
      };
    },
    getProjectData () {
      getProjectPage({
        ...this.projectSearchParam
      }).then(res => {
        const { projectList, totalSize, categoryTotal } = res.data.data;
        this.list = projectList;
        this.totalSize = totalSize;
        this.setCategoryTotal(categoryTotal);
      }).then(() => {
        this.getCategoryTotal();
      });
    },
    getCategoryTotal () {
      this.labels.forEach((label, ind) => {
        this.categoryTotal.forEach((item, index) => {
          if (item.name === label.name) {
            label.total = item.total;
            label.typeId = item.typeId;
          }
        });
      });
    },
    getBasicData () {
      const {
        provName, areaName, cityName, streetName, competent, education,
        enterpriseGroup, category, companyOrgType,
        businessProfile, intellectualProperty, certification
      } = this.basicData;
      this.basicData.addressTxt = this.handleAddressTxt(provName, areaName, cityName, streetName);
      this.basicData.companyOrgTypeTxt = companyOrgType !== null ? companyOrgType : '未知';
      this.basicData.enterpriseGroupTxt = enterpriseGroup !== null ? enterpriseGroup.tagName : '未知';
      this.basicData.categoryTxt = category !== null ? category.tagName + (category.bigCategory ? '/' + category.bigCategory.tagName : '') : '未知';
      this.basicData.educationTxt = competent !== null ? competent.map(item => item.tagName).join(',') : '未知';
      this.basicData.competentTxt = education !== null ? education.map(item => item.tagName).join(',') : '未知';
      let arr, children;
      this.basicDatas.forEach(item => {
        switch (item.title) {
          case '基本情况':
            if (provName === null || education === null || competent === null || enterpriseGroup === null || category === null || companyOrgType === null) {
              item.status = 2;
            } else {
              item.status = 1;
            }
            item.children.forEach(sub => {
              sub.content = this.basicData[sub.field];
              sub.status = sub.content === '未知' ? 2 : 3;
            });
            break;
          case '经营概况':
            arr = businessProfile.filter(item => item.isChecked === null);
            item.status = arr.length > 0 ? 2 : 1;
            children = [];
            businessProfile.forEach(sub => {
              const obj = {
                title: sub.tagName,
                content: '',
                status: 3
              };
              if (sub.isChecked === null) {
                obj.content = '未知';
                obj.status = 2;
              } else {
                obj.content = sub.isChecked ? '有' : '无';
              }
              children.push(obj);
            });
            item.children = children;
            break;
          case '知识产权':
            arr = intellectualProperty.filter(item => item.num === null);
            item.status = arr.length > 0 ? 2 : 1;
            children = [];
            intellectualProperty.forEach(sub => {
              const obj = {
                title: sub.tagName,
                content: '',
                status: 3
              };
              if (sub.num === null) {
                obj.content = '未知';
                obj.status = 2;
              } else {
                obj.content = `${sub.num}项`;
              }
              children.push(obj);
            });
            item.children = children;
            break;
          case '资质认证':
            item.children[0].content = '查看';
            if (certification !== null && certification.length > 0) {
              item.children[0].title = `${certification.length}项`;
              item.status = 1;
              this.certification = certification;
            } else {
              item.status = 2;
              this.certification = [];
            }
            // item.status = certification.length > 0 ? 1 : 2
            break;
        }
      });
    },
    handleAddressTxt (provName, areaName, cityName, streetName) {
      let addressTxt = '';
      if (provName !== null) {
        addressTxt = provName;
        if (cityName !== null) {
          addressTxt = addressTxt + '/' + cityName;
          if (areaName !== null) {
            addressTxt = addressTxt + '/' + areaName;
            if (streetName !== null) {
              addressTxt = addressTxt + '/' + streetName;
            }
          }
        }
      } else {
        addressTxt = '未知';
      }
      return addressTxt;
    },
    handleBasicClick () {
      this.dialogVisible = true;
      this.componentName = 'ShowData';
      this.dialogType = '资质认证';
      this.dialogWidth = '25%';
      this.dialogConfig = {
        title: '资质认证',
        num: 15,
        list: this.certification
      };
    },
    handleLabel (index, item) {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      this.labels.forEach((item, ind) => {
        item.show = index === ind;
      });
      this.projectSearchParam.typeId = item.typeId;
      this.projectSearchParam.pageNo = 1;
      this.projectSearchParam.userId = this.userId;
      this.getProjectData();
    },
    closeDialog (type) {
      this.dialogVisible = false;
      this.getBasicData();
      this.resetTagGroupLists();
      this.projectSearchParam.typeId = '';
      this.projectSearchParam.pageNo = 1;
      this.labels.forEach((item, index) => { item.show = index === 0; });
      this.getProjectData();
      if (this.stars !== 5 || type === 'reset') {
        this.handleStars();
      } else {
        this.resetProCurTag();
      }
    },
    handleStars () {
      const {
        provCode, competent, education,
        enterpriseGroup, category, companyOrgType,
        businessProfile, intellectualProperty, certification
      } = this.basicData;
      let starsArr = [];
      let arr = [];
      if (provCode === null || education === null || competent === null || enterpriseGroup === null || category === null || companyOrgType === null) {
        starsArr.push({
          status: 2
        });
      } else {
        starsArr.push({
          status: 1
        });
      }
      arr = businessProfile.filter(item => item.isChecked === null);
      if (arr.length > 0) {
        starsArr.push({
          status: 2
        });
      } else {
        starsArr.push({
          status: 1
        });
      }
      arr = intellectualProperty.filter(item => item.num === null);
      if (arr.length > 0) {
        starsArr.push({
          status: 2
        });
      } else {
        starsArr.push({
          status: 1
        });
      }
      if (certification.length <= 0) {
        starsArr.push({
          status: 2
        });
      } else {
        starsArr.push({
          status: 1
        });
      }
      starsArr = starsArr.filter(item => item.status === 2);
      let currStars = 1;
      if (starsArr.length === 0) {
        currStars = 3;
      } else if (starsArr.length <= 3) {
        currStars = 2;
      } else {
        currStars = 1;
      }
      const len = this.progressBar.length;
      if (len > 0) {
        const pro = this.progressBar[len - 1];
        if (pro >= 0.5 && pro < 1) {
          currStars += 1;
        } else if (pro === 1) {
          currStars += 2;
        }
      }
      this.setStars(currStars);
      this.resetProCurTag();
    },
    editData () {
      if (this.showVipCom) {
        this.dialogVisible = true;
        this.dialogWidth = '0%';
        this.componentName = 'vipPermission';
        return;
      }
      this.dialogVisible = true;
      this.componentName = 'EditData';
      this.dialogType = '编辑信息';
      this.dialogWidth = '50%';
      this.dialogConfig = {
        title: '资质认证',
        num: 15
      };
    },
    goToPerfect () {
      this.componentName = 'CheckLabel';
      this.dialogType = '行业政策线标签';
      this.dialogWidth = '50%';
      this.dialogConfig = {
        title: '资质认证',
        num: 15
      };
    },
    changeCompany () {
      // 更换企业
      this.$router.push('/simpleMatching/search');
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__header {
  border-bottom: 1px solid rgba(187, 187, 187, 0.5);
}
.header {
  .header_title {
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    font-weight: bold;
  }
  .header_num {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    margin-left: 13px;
  }
  .header_label_title {
    .header_label_title_left {
      color: rgba(51, 51, 51, 1);
      font-size: 20px;
      margin-right: 10px;
    }
    .header_label_title_right {
      color: rgba(97, 119, 154, 1);
      font-size: 14px;
    }
  }
  .header_label_progress {
    margin-top: 10px;
    display: flex;
    .progress_txt {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      margin-right: 12px;
    }
    .progress {
      width: 350px;
    }
    .progress_success {
      margin-left: -5px;
      width: 18px;
      height: 18px;
      background-image: url("../../../../assets/pc/images/simpleMatching/success.png");
      background-size: 18px 18px;
    }
  }
}
.result {
  display: flex;
  align-items: flex-start;
  margin-top: 25px;
  .left {
    width: 300px;
    padding: 18px 7px 25px;
    margin-right: 20px;
    .left_com {
      font-size: 14px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      .left_icon {
        width: 25px;
        height: 25px;
        background-image: url("../../../../assets/pc/images/simpleMatching/company.png");
        background-size: 25px 25px;
        // font-size: 18px;
        // font-weight: bold;
        // color: rgba(48, 120, 240, 1);
        margin-right: 10px;
      }
      .left_txt {
        margin-left: 8px;
        cursor: pointer;
        font-weight: 500;
        color: rgba(48, 120, 240, 1);
      }
      .left_company_name {
        max-width: 180px;
      }
    }
    .left_project {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      .project_img {
        margin-top: 20px;
        margin-bottom: 12px;
        width: 182px;
        height: 182px;
        background-image: url("../../../../assets/pc/images/simpleMatching/project_bg.png");
        background-size: 182px 182px;
        // background-color: #156ED0;
        // background: radial-gradient(0.5000000000000001% 0.49999999999999994% at 50.5% 50.7%, rgba(188,214,253,0) 0%,rgba(48,120,240,0.1) 100%);
        text-align: center;
        // border-radius: 50%;
        color: rgba(65, 96, 146, 1);
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .img_num {
          color: rgba(255, 114, 0, 1);
          font-size: 40px;
        }
        .img_unit {
          color: rgba(65, 96, 146, 1);
          font-size: 14px;
        }
      }
      .img_tip {
        color: rgba(65, 96, 146, 0.6);
        font-size: 12px;
      }
    }
    .left_basic {
      cursor: pointer;
      .basic_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 12px;
        height: 36px;
        border-radius: 5px;
        padding: 0 8px;
        .basic_left {
          color: rgba(0, 0, 0, 1);
        }
      }
      .basic_header:hover {
        background-color: rgba(48, 120, 240, 0.05);
      }
      .basic_content {
        color: rgba(0, 0, 0, .5);
        font-size: 12px;
        margin-bottom: 16px;
        .content {
          display: flex;
          justify-content: space-between;
          padding: 0 8px 0 28px;
          .content_txt {
            text-align: right;
            width: 129px;
            .content_txt_btn {
              cursor: pointer;
            }
          }
        }
      }
    }
    .left_btn {
      text-align: center;
      .btn {
        width: 270px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(48, 120, 240, 1);
        font-size: 14px;
        text-align: center;
        font-family: Roboto;
        cursor: pointer;
      }
      .btn:hover {
        opacity: 0.7;
      }
      .btn_done {
        background-color: rgba(255, 255, 255, 1);
        color: rgba(48, 120, 240, 1);
      }
      .btn_doing {
        background-color: rgba(48, 120, 240, 1);
        color: rgba(255, 255, 255, 1);
      }
    }
    .left_bottom {
      text-align: center;
      color: rgba(65, 96, 146, 0.6);
      font-size: 14px;
      margin-top: 20px;
      .bottom_header {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        .rate_txt {
          margin-right: 5px;
        }
      }
    }
  }
  .right {
    width: 770px;
    position: relative;
    .right_header {
      padding: 27px 17px 20px 21px;
      border-bottom: 1px solid rgba(187, 187, 187, 0.5);
      .header_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header_top_left {
          display: flex;
          align-items: center;
          .top_left_status {
            color: rgba(7, 193, 96, 1);
            font-size: 24px;
            display: flex;
            align-items: center;
            .top_left_status_icon {
              width: 28px;
              height: 28px;
              background-image: url("../../../../assets/pc/images/simpleMatching/mathing_success.png");
              background-size: 28px 28px;
            }
          }
          .top_left_tip {
            color: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            display: flex;
            align-items: center;
            .opportunity {
              // color: #FAC909;
              // font-size: 16px;
              width: 18px;
              height: 18px;
              background-image: url("../../../../assets/pc/images/simpleMatching/light.png");
              background-size: 18px 18px;
              margin-left: 25px;
            }
          }
        }
        .header_top_right {
          cursor: pointer;
          color: rgba(65, 96, 146, 0.6);
          font-size: 14px;
        }
      }
      .header_label {
        display: flex;
        align-items: center;
        .label_btn {
          cursor: pointer;
          height: 28px;
          font-size: 12px;
          text-align: center;
          font-family: Microsoft Yahei;
          line-height: 28px;
          text-align: center;
          border-radius: 50px;
          padding: 0 15px;
          margin-top: 30px;
          margin-right: 12px;
        }
        .btn_active {
          background-color: rgba(48, 120, 240, 1);
          color: rgba(255, 255, 255, 1);
        }
        .btn_active:hover {
          opacity: 0.7;
        }
        .btn_inactive {
          background-color: #fff;
          color: rgba(0, 0, 0, 0.5);
          border: 1px solid rgba(187, 187, 187, 0.5);
        }
      }
    }
    .right_content_supple {
      margin-bottom: 62px;
    }
    .right_content {
      min-height: 453px;
      .content_item {
        padding: 20px 17px 20px 22px;
        border-top: 1px solid rgba(187, 187, 187, 0.5);
        border-bottom: 1px solid rgba(187, 187, 187, 0.5);
        display: flex;
        justify-content: space-between;
        .item_left {
          .item_title {
            cursor: pointer;
            color: rgba(16, 16, 16, 1);
            font-size: 18px;
            margin-bottom: 15px;
            font-weight: bold;
          }
          .item_label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            .label_day {
              border-radius: 4px;
              font-size: 12px;
              text-align: center;
              padding: 5px;
              margin-right: 11px;
            }
            .label_day_open {
              background-color: rgba(255, 0, 0, 0.05);
              color: rgba(255, 0, 0, 1);
            }
            .label_day_close {
              background-color: rgba(97, 119, 154, 0.05);
              color: rgba(97, 119, 154, 1);
            }
            .label_type {
              border-radius: 4px;
              // background-color: rgba(48, 120, 240, 0.05);
              // color: rgba(48, 120, 240, 1);
              font-size: 12px;
              text-align: center;
              padding: 5px;
              margin-right: 11px;
            }
            .label_level {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.8);
              font-size: 12px;
              text-align: center;
              padding: 5px;
            }
          }
          .item_bottom {
            color: rgba(0, 0, 0, 0.5);
            font-size: 14px;
            text-align: left;
            .bottom_left {
              margin-right: 30px;
            }
          }
        }
        .item_right {
          display: flex;
          align-items: flex-end;
          .item_btn {
            cursor: pointer;
            text-align: center;
            border-radius: 4px;
            font-size: 14px;
            padding: 10px 20px;
            width: 66px;
            // height: 32px;
          }
          .item_btn:hover {
            opacity: 0.8;
          }
          .left_btn_active {
            background-color: #fff;
            color: rgba(48, 120, 240, 1);
            border: 1px solid rgba(48, 120, 240, 1);
            margin-right: 13px;
          }
          .left_btn_inactive {
            background-color: rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.8);
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 13px;
          }
          .right_btn_inactive {
            background-color: rgba(0, 0, 0, 0.1);
            color: rgba(0, 0, 0, 0.8);
            border: 1px solid rgba(0, 0, 0, 0.1);
          }
          .right_btn_active {
            color: rgba(255, 255, 255, 1);
            background-color: rgba(48, 120, 240, 1);
            border: 1px solid rgba(48, 120, 240, 1);
          }
        }
      }
      .item_empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 453px;
        .empty_img {
          width: 162px;
          height: 162px;
          // background-color: rgba(48, 120, 240, 1);
          background-image: url("../../../../assets/pc/images/simpleMatching/empty.png");
          background-size: 162px 162px;
          // background-color: rgba(255, 255, 255, 1);
        }
        .empty_txt {
          color: rgba(97, 119, 154, 0.6);
          font-size: 14px;
          text-align: center;
          margin-top: 10px;
        }
        .empty_btn {
          width: 140px;
          height: 48px;
          line-height: 48px;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 1);
          color: rgba(48, 120, 240, 1);
          font-size: 16px;
          text-align: center;
          font-family: Roboto;
          border: 1px solid rgba(48, 120, 240, 1);
          margin: 20px auto 0;
        }
      }
    }
    .right_page {
      width: 100%;
      position: absolute;
      bottom: 0;
      border-top: 1px solid rgba(187, 187, 187, 0.5);
      .page_box {
        height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        .page_btn {
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px;
        }
        .page_num {
          margin: 0 30px;
          .current_page {
            color: rgba(48, 120, 240, 1);
          }
        }
      }
    }
  }
}
.border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 3px .5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.dialogTitle {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
}
</style>