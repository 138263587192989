<template>
  <div class="check_label">
    <div v-if="!labelList || labelList.length <= 0" class="label_empty">
      <div class="label_empty_img"></div>
      <div class="label_empty_txt">数据已完善</div>
      <div class="label_empty_tip">您已完善所有信息，可点击完成去查看结果或点击上一步返回修改</div>
      <div class="label_empty_sure" @click="complete">
        完成
      </div>
      <div class="label_empty_prev" @click="prevStep">上一步</div>
    </div>
    <div v-else>
      <div
        v-for="(item, index) in labelList"
        :key="index"
        class="label_box"
      >
        <div class="label_title">
          {{item.groupName}}
        </div>
        <div class="label_content_box">
          <div
            v-for="(sub, ind) in item.tagList"
            :key="ind"
            class="label_content"
            @click="() => sub.isChecked = !sub.isChecked"
          >
            <div :class="['label_btn', sub.isChecked ? 'btn_active' : 'btn_inactive']">{{sub.tagName}}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer footer" :style="{ justifyContent: labelList.length > 0 ? 'space-between' : 'flex-end' }">
        <div v-show="labelList.length > 0" class="item_btn foot_left" @click="directMatch">
          直接匹配
        </div>
        <div class="foot_right">
          <div v-if="curTagPage > 1" class="foot_right_left" @click="prevStep">
            上一步
          </div>
          <div class="item_btn foot_right_right" @click="nextStep">
            {{'下一步'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getNextStep, getPrevStep } from '@/api/simple_matching/index';
import { getMemberId } from '@/assets/public/utils/token';

export default {
  data () {
    return {
      page: 1,
      total: 5,
      labelList: [],
      isOver: false
    };
  },
  computed: {
    ...mapState('simpleMatching', [
      'tagGroupLists', 'curTagPage', 'companyName', 'basicData', 'recordId', 'stars', 'progressBar'
    ]),
    userId () {
      return getMemberId();
    },
    params () {
      return {
        directMatching: false, // 是否直接匹配
        userId: this.userId, // 用户id
        entityName: this.companyName, // 主体名称
        categoryId: this.basicData.category.tagId, // 所属行业id
        recordId: this.recordId, // 记录id
        overOperation: false, // 是否完成且查看结果
        step: this.curTagPage + 1, // 步骤
        tagGroupList: []
      };
    }
  },
  mounted () {
    this.initData();
  },
  methods: {
    ...mapMutations('simpleMatching', [
      'pushTagGroupLists', 'popTagGroupLists', 'pushProgressBar',
      'popProgressBar', 'setStars'
    ]),
    initData () {
      this.labelList = this.tagGroupLists[this.curTagPage - 1];
    },
    handleNextStep (data) {
      const { tagGroupList, progressBar, isOver } = data;
      this.pushTagGroupLists(tagGroupList);
      this.pushProgressBar(progressBar);
      this.isOver = isOver;
      this.$nextTick(() => {
        this.initData();
      });
    },
    complete () {
      this.params.overOperation = true;
      const arr = [];
      this.tagGroupLists.forEach(item => {
        item.forEach(sub => {
          arr.push(sub);
        });
      });
      this.params.tagGroupList = arr;
      getNextStep({
        ...this.params
      }).then(res => {
        // this.handleNextStep(res.data.data)
        this.$emit('close');
      });
    },
    directMatch () {
      this.params.directMatching = true;
      const arr = [];
      this.tagGroupLists.forEach(item => {
        item.forEach(sub => {
          arr.push(sub);
        });
      });
      this.params.tagGroupList = arr;
      getNextStep({
        ...this.params
      }).then(res => {
        // this.handleNextStep(res.data.data)
        this.$emit('close');
      });
    },
    prevStep () {
      getPrevStep({
        userId: this.userId,
        toStep: this.curTagPage - 1
      }).then(res => {
        this.popTagGroupLists();
        this.popProgressBar();
        this.$nextTick(() => {
          this.initData();
        });
      });
    },
    nextStep () {
      const arr = [];
      this.tagGroupLists.forEach(item => {
        item.forEach(sub => {
          arr.push(sub);
        });
      });
      this.params.tagGroupList = arr;
      getNextStep({
        ...this.params
      }).then(res => {
        this.handleNextStep(res.data.data);
      });
    }
  }
};
</script>

<style scoped lang="less">
.label_empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  .label_empty_img {
    width: 42px;
    height: 42px;
    background-image: url("../../../../../assets/pc/images/simpleMatching/empty_label.png");
    background-size: 42px 42px;
    margin-bottom: 10px;
    margin-top: 46px;
  }
  .label_empty_txt {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }
  .label_empty_tip {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    text-align: center;
    width: 252px;
    margin-bottom: 60px;
  }
  .label_empty_sure {
    cursor: pointer;
    width: 140px;
    height: 48px;
    line-height: 48px;
    border-radius: 5px;
    background-color: rgba(48, 120, 240, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    text-align: center;
    margin-bottom: 18px;
  }
  .label_empty_sure:hover {
    opacity: 0.7;
  }
  .label_empty_prev {
    cursor: pointer;
    color: rgba(48, 120, 240, 1);
    font-size: 14px;
    margin-bottom: 50px;
  }
}
.label_box {
  .label_title {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    padding-left: 5px;
    border-left: 3px solid rgba(0, 0, 0, 1);
  }
  .label_content_box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px 22px 22px 22px;
    .label_content {
      margin: 0 10px 12px 0;
      .label_btn {
        cursor: pointer;
        border-radius: 3px;
        font-size: 14px;
        text-align: center;
        padding: 8px 13px;
      }
      .btn_active {
        background-color: rgba(235, 243, 255, 1);
        color: rgba(48, 120, 240, 1);
        border: 1px solid rgba(48, 120, 240, 1);
      }
      .btn_inactive {
        background-color: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.6);
        border: 1px solid rgba(0, 0, 0, 0.01);
      }
    }
  }
}
.footer {
  margin-top: 20px;
  display: flex;
  // flex-direction: row-reverse;
  // justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 19px;
  .item_btn {
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    font-family: Roboto;
    font-weight: bold;
  }
  .item_btn:hover {
    opacity: 0.7;
  }
  .foot_left {
    border: 1px solid rgba(48, 120, 240, 1);
    background-color: #fff;
    color: rgba(48, 120, 240, 1);
    padding: 15px 30px;
  }
  .foot_right {
    display: flex;
    justify-content: center;
    align-items: center;
    .foot_right_left {
      cursor: pointer;
      color: rgba(48, 120, 240, 1);
      margin-right: 60px;
      font-size: 16px;
      font-weight: bold;
    }
    .foot_right_left:hover {
      opacity: 0.7;
    }
    .foot_right_right {
      border: 1px solid rgba(48, 120, 240, 1);
      color: #fff;
      background-color: rgba(48, 120, 240, 1);
      padding: 15px 40px;
    }
  }
}
</style>
