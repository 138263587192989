<template>
  <div class="edit_data">
    <div class="edit_box">
      <div class="box_title">基本情况</div>
      <div class="box_content">
        <div class="label_title">注册地址</div>
        <div class="label_select label_address">
          <!-- <el-cascader
            class="select_one"
            style="width: 300px"
            v-model="form.address"
            :props="props"
            @change="handleChange"
          ></el-cascader>
          <el-select
            v-if="form.address.length > 0"
            v-model="form.streetCode"
            style="width: 300px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in streetOpt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select> -->
          <el-select
            v-model="addressObj.provCode"
            placeholder="请选择"
            clearable
            class="label_address_select"
            @change="handleAreaChange($event, 'prov')"
          >
            <el-option
              v-for="item in addressObj.provOpt"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
          <el-select
            v-if="addressObj.cityOpt.length > 0"
            v-model="addressObj.cityCode"
            placeholder="请选择"
            clearable
            class="label_address_select"
            @change="handleAreaChange($event, 'city')"
            :disabled="addressObj.provCode === ''"
          >
            <el-option
              v-for="item in addressObj.cityOpt"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
          <el-select
            v-if="addressObj.areaOpt.length > 0"
            v-model="addressObj.areaCode"
            placeholder="请选择"
            clearable
            class="label_address_select"
            @change="handleAreaChange($event, 'area')"
            :disabled="addressObj.cityCode === ''"
          >
            <el-option
              v-for="item in addressObj.areaOpt"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
          <el-select
            v-if="addressObj.streetOpt.length > 0"
            v-model="addressObj.streetCode"
            placeholder="请选择"
            clearable
            class="label_address_select"
            @change="handleAreaChange($event, 'street')"
            :disabled="addressObj.areaCode === ''"
          >
            <el-option
              v-for="item in addressObj.streetOpt"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </div>
        <div class="label_title">企业类型</div>
        <div class="label_select">
          <!-- <el-select
            v-model="form.companyOrgType"
            placeholder="请选择"
            clearable
            multiple
            style="width: 300px"
          >
            <el-option
              v-for="item in companyOrgTypeOpt"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
            </el-option>
          </el-select> -->
          <el-autocomplete
            id="autocomplete2"
            class="certification_input"
            v-model="form.companyOrgType"
            :fetch-suggestions="querySearchOrgType"
            placeholder="搜索企业类型"
            @select="handleSelectOryType"
            :debounce="500"
            clearable
            popper-class="autocomplete_input_popper_class"
            style="width: 300px"
          >
            <template slot-scope="{ item }">
              <div v-html="item"></div>
            </template>
          </el-autocomplete>
        </div>
        <div class="label_title">企业规模</div>
        <div class="label_select select_group">
          <el-select
            v-model="form.enterpriseGroup"
            placeholder="请选择"
            clearable
            style="width: 300px"
          >
            <el-option
              v-for="item in enterpriseGroupOpt"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
            </el-option>
          </el-select>
          <div @click="handleStandard" class="select_group_img">
            <!-- <i class="el-icon-question"></i> -->
            <div class="select_group_img_question"></div>
            <span>参考标准</span>
          </div>
        </div>
        <div class="label_title">所属行业</div>
        <div class="label_select">
          <el-select
            class="select_one"
            v-model="form.category"
            placeholder="请选择"
            clearable
            style="width: 300px"
            @change="handleCategory"
          >
            <el-option
              v-for="item in categoryOpt"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
            </el-option>
          </el-select>
          <el-select
            v-model="form.categoryBig"
            clearable
            placeholder="请选择"
            style="width: 300px"
            :disabled="form.category === ''"
          >
            <el-option
              v-for="item in categoryBigOpt"
              :key="item.tagId"
              :label="item.tagName"
              :value="item.tagId">
            </el-option>
          </el-select>
        </div>
        <div class="label_title">学历组成</div>
        <div class="label_select select_flex">
          <div
            v-for="(item, index) in educationOpt"
            :key="index"
            :class="[item.show ? 'radio_active' : 'radio_inactive', 'radio']"
            @click="handleClickEdu(index, educationOpt, item, 'edu')"
          >
            {{ item.tagName }}
          </div>
        </div>
        <div class="label_title">职称组成</div>
        <div class="label_select select_flex">
          <div
            v-for="(item, index) in competentOpt"
            :key="index"
            :class="[item.show ? 'radio_active' : 'radio_inactive', 'radio']"
            @click="handleClickEdu(index, competentOpt, item, 'com')"
          >
            {{ item.tagName }}
          </div>
        </div>
      </div>
      <!-- <div class="box_content"></div> -->
    </div>
    <div class="edit_box">
      <div class="box_title box_title_supply">经营概况</div>
      <div class="box_content box_content_flex">
        <div
          v-for="item in businessProfile"
          :key="item.tagId"
          class="content_item"
        >
          <div class="item_title">{{item.tagName}}</div>
          <div class="item_select">
            <el-radio-group v-model="item.isChecked" size="mini">
              <el-radio-button :label="true">有</el-radio-button>
              <el-radio-button :label="false">无</el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="edit_box">
      <div class="box_title box_title_supply">知识产权</div>
      <div class="box_content box_content_flex">
        <div
          v-for="item in intellectualProperty"
          :key="item.tagId"
          class="content_item"
        >
          <div class="item_title intellectual_box">{{item.tagName}}</div>
          <div class="item_select">
            <el-input-number
              v-model="item.num"
              style="width: 100px"
              controls-position="right"
              @change="handleChange($event, item)"
              :min="0"
            ></el-input-number>
          </div>
          件
        </div>
      </div>
    </div>
    <div class="edit_box">
      <div class="box_title box_title_supply box_title_flex">
        <div class="box_title_txt">资质认证</div>
        <div class="box_title_btn">
          <div v-if="certShow">
            <el-autocomplete
              v-if="certType === 'add'"
              id="autocomplete"
              class="certification_input"
              popper-class="autocomplete_input_popper_class"
              v-model="searchValue"
              :fetch-suggestions="querySearch"
              placeholder="搜索资质认证"
              @select="handleSelect"
              :debounce="500"
              clearable
              size="mini"
            >
              <template slot-scope="{ item }">
                <div v-html="item"></div>
              </template>
            </el-autocomplete>
            <el-button v-if="certType === 'edit'" type="primary" @click="sureCert" size="mini">保存</el-button>
            <el-button @click="cancelCert" size="mini">取消</el-button>
          </div>
          <div v-else>
            <el-button type="info" plain icon="el-icon-edit" size="mini" :disabled="certification.length == 0" @click="handleCertification('edit')"></el-button>
            <el-button type="info" plain icon="el-icon-plus" size="mini" @click="handleCertification('add')"></el-button>
          </div>
        </div>
      </div>
      <div class="box_content box_content_flex">
        <div
          v-for="(item, index) in certification"
          :key="index"
          :class="['certification_item', certType === 'add' ? '': 'cert_active_item']"
        >
          <div class="item_title">{{item}}</div>
          <i v-if="certType === 'edit'" class="el-icon-close certification_item_icon" @click="deleteCert(item)"></i>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer footer">
      <div class="footer_left" @click="resetLabel">
        <i class="el-icon-refresh-right"></i>还原默认标签
      </div>
      <div class="footer_right">
        <div class="item_btn left_btn_active" @click="sureAndPerfect('sure')">
          保存修改
        </div>
        <div class="item_btn right_btn" @click="sureAndPerfect('sure&per')">保存并继续完善</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getAreaByCode, getOtherBasicDataList, getBigCategoryList, getMatch, searchCertification, searchCompanyOrgType } from '@/api/simple_matching/index';
import { getMemberId } from '@/assets/public/utils/token';

export default {
  data () {
    return {
      oldCertList: [],
      certType: 'add',
      certShow: false,
      searchValue: '',
      restaurants: [],
      restaurants2: [],
      form: {
        companyOrgType: '',
        enterpriseGroup: '',
        category: '',
        categoryBig: '',
        education: [],
        competent: []
      },
      doneBasicData: { // 修改基础信息
        provName: null,
        provCode: null,
        cityCode: null,
        streetCode: null,
        areaCode: '',
        streetName: null,
        cityName: '',
        areaName: '',
        education: [
        ],
        competent: [
        ],
        intellectualProperty: [
        ],
        businessProfile: [
        ],
        companyOrgType: '',
        certification: [
        ],
        enterpriseGroup: {
          tagId: '1535078290017914881',
          tagName: '大型企业',
          tagRemark: null
        },
        category: {
          tagId: '1535077754803752961',
          tagName: '制造业',
          bigCategory: {
            tagId: '1549675109152485378',
            tagName: '电气机械和器材制造业'
          }
        }
      },
      certification: [],
      enterpriseGroupOpt: [],
      categoryOpt: [],
      categoryBigOpt: [],
      educationOpt: [
      ],
      competentOpt: [
      ],
      businessProfile: [
        {
          tagId: '1535077739993665538',
          tagName: '参展参会',
          isChecked: null
        },
        {
          tagId: '1535077821879062529',
          tagName: '产学研合作',
          isChecked: null
        },
        {
          tagId: '1535077996957700097',
          tagName: '上市',
          isChecked: null
        }
      ],
      intellectualProperty: [
        {
          tagId: '1535077923993587713',
          num: null,
          tagName: '实用新型专利'
        },
        {
          tagId: '1535077924824059905',
          num: null,
          tagName: '外观设计专利'
        },
        {
          tagId: '1535077925650337794',
          num: null,
          tagName: '发明专利'
        },
        {
          tagId: '1535078067711414274',
          num: null,
          tagName: '作品著作权'
        },
        {
          tagId: '1535078106810716161',
          num: null,
          tagName: '软件著作权'
        },
        {
          tagId: '1575024972692156418',
          num: null,
          tagName: '商标'
        }
      ],
      // props: {
      //   expandTrigger: 'hover',
      //   checkStrictly: true,
      //   lazy: true,
      //   lazyLoad (node, resolve) {
      //     const { level } = node
      //     
      //   }
      // },
      addressObj: {
        provCode: '',
        provOpt: [], // 省
        cityCode: '',
        cityOpt: [], // 市
        areaCode: '',
        areaOpt: [], // 区
        streetCode: '',
        streetOpt: [] // 街道
      }
    };
  },
  computed: {
    ...mapState('simpleMatching', [ 'basicData', 'companyName', 'recordId' ]),
    userId () {
      return getMemberId();
    },
    editParams () {
      return {
        analysisJump: false, // 是否分析跳入
        entityName: this.companyName, // 主体名称
        memberId: getMemberId(), // 用户id
        sysType: 1, // 系统类型
        restoreOperation: false, // 还原默认标签（true的时候doneBasicData不用传）
        perfectOperation: false, // 保存并且继续完善时候传true
        recordId: this.recordId // 记录id（分析跳转后会返回）
      };
    }
  },
  mounted () {
    this.getArea('000000').then(res => {
      this.addressObj.provOpt = res.data.data;
    }).then(() => {
      getOtherBasicDataList().then(res => {
        const {
          category,
          competent,
          education,
          enterpriseGroup
        } = res.data.data;
        this.enterpriseGroupOpt = enterpriseGroup;
        this.categoryOpt = category;
        competent.forEach(item => {
          const obj = {
            ...item,
            show: false
          };
          this.competentOpt.push(obj);
        });
        education.forEach(item => {
          const obj = {
            ...item,
            show: false
          };
          this.educationOpt.push(obj);
        });
        this.showData();
      });
    });
  },
  methods: {
    ...mapMutations('simpleMatching', [ 'setBasicDatas', 'pushTagGroupLists', 'pushProgressBar' ]),
    querySearch (queryString, cb) {
      if (queryString !== '' && queryString !== null) {
        searchCertification({ name: queryString }).then(res => {
          this.restaurants = res.data.data;
          const results = this.restaurants;
          cb(results);
        });
      } else {
        const results = [];
        cb(results);
      }
    },
    querySearchOrgType (queryString, cb) {
      if (queryString !== '' && queryString !== null) {
        searchCompanyOrgType({ name: queryString }).then(res => {
          this.restaurants2 = res.data.data;
          const results = this.restaurants2;
          cb(results);
        });
      } else {
        const results = [];
        cb(results);
      }
    },
    handleSelect (e) {
      if (this.certification.indexOf(e) === -1) this.certification.push(e);
    },
    handleSelectOryType (e) {
      this.form.companyOrgType = e;
    },
    deleteCert (e) {
      this.certification = this.certification.filter(item => item !== e);
    },
    sureCert () {
      this.oldCertList = [];
      this.certType = 'add';
      this.certShow = false;
    },
    cancelCert () {
      if (this.oldCertList.length > 0) {
        this.certification = this.oldCertList;
      }
      this.certType = 'add';
      this.certShow = false;
    },
    handleCertification (type) {
      this.oldCertList = this.certification;
      this.certType = type;
      this.certShow = true;
    },
    handleCategory (e) {
      this.form.categoryBig = '';
      this.categoryBigOpt = [];
      if (e !== '') {
        getBigCategoryList({ categoryId: e }).then(res => {
          this.categoryBigOpt = res.data.data;
        });
      }
    },
    showData () {
      const {
        provCode, cityCode, areaCode, streetCode,
        competent, education,
        enterpriseGroup, category, companyOrgType,
        businessProfile, intellectualProperty,
        certification
      } = this.basicData;
      // 注册地址
      this.$nextTick(() => {
        this.getArea('000000').then(res => {
          this.addressObj.provOpt = res.data.data;
          this.addressObj.provCode = provCode;
          if (provCode !== null) {
            this.getArea(provCode).then(city => {
              this.addressObj.cityOpt = city.data.data;
              this.addressObj.cityCode = cityCode;
              if (cityCode !== null) {
                this.getArea(cityCode).then(area => {
                  this.addressObj.areaOpt = area.data.data;
                  this.addressObj.areaCode = areaCode;
                  if (areaCode !== null) {
                    this.getArea(areaCode).then(street => {
                      this.addressObj.streetOpt = street.data.data;
                      this.addressObj.streetCode = streetCode;
                    });
                  }
                });
              }
            });
          }
        });
      });
      // 企业类型
      this.form.companyOrgType = companyOrgType === null ? '' : companyOrgType;
      // 企业规模
      this.form.enterpriseGroup = enterpriseGroup === null ? '' : enterpriseGroup.tagId;
      // 所属行业
      if (category !== null) {
        this.form.category = category.tagId;
        if (category.bigCategory !== null) {
          getBigCategoryList({ categoryId: category.tagId }).then(res => {
            this.categoryBigOpt = res.data.data;
            this.form.categoryBig = category.bigCategory.tagId;
          });
        }
      }
      // 学历
      if (education !== null) {
        const edu = education.map(item => item.tagId);
        this.educationOpt.forEach(item => {
          if (edu.includes(item.tagId)) {
            item.show = true;
            this.form.education.push(item);
          }
        });
      }
      // 职称
      if (competent !== null) {
        const con = competent.map(item => item.tagId);
        this.competentOpt.forEach(item => {
          if (con.includes(item.tagId)) {
            item.show = true;
            this.form.competent.push(item);
          }
        });
      }
      // 经营概况
      this.businessProfile = [];
      businessProfile.forEach(item => {
        const obj = {
          tagId: item.tagId,
          tagName: item.tagName,
          isChecked: item.isChecked
        };
        this.businessProfile.push(obj);
      });
      // this.businessProfile = [...businessProfile]
      // 知识产权
      // this.intellectualProperty = [...intellectualProperty]
      this.intellectualProperty = [];
      intellectualProperty.forEach(item => {
        const obj = {
          tagId: item.tagId,
          tagName: item.tagName,
          num: item.num
        };
        this.intellectualProperty.push(obj);
      });
      // 资质认证
      this.certification = [ ...certification ];
    },
    async getArea (code) {
      return getAreaByCode({ code: code });
    },
    handleAreaChange (e, type) {
      switch (type) {
        case 'prov':
          this.addressObj.cityCode = '';
          this.addressObj.cityOpt = [];
          this.addressObj.areaCode = '';
          this.addressObj.areaOpt = [];
          this.addressObj.streetCode = '';
          this.addressObj.streetOpt = [];
          if (e !== '') {
            this.getArea(e).then(res => {
              this.addressObj.cityOpt = res.data.data;
            });
          }
          break;
        case 'city':
          this.addressObj.areaCode = '';
          this.addressObj.areaOpt = [];
          this.addressObj.streetCode = '';
          this.addressObj.streetOpt = [];
          if (e !== '') {
            this.getArea(e).then(res => {
              this.addressObj.areaOpt = res.data.data;
            });
          }
          break;
        case 'area':
          this.addressObj.streetCode = '';
          this.addressObj.streetOpt = [];
          if (e !== '') {
            this.getArea(e).then(res => {
              this.addressObj.streetOpt = res.data.data;
            });
          }
          break;
      }
    },
    handleChange (e, item) {

    },
    handleClickEdu (index, list, item, type) {
      list.forEach((item, ind) => {
        if (ind === index) {
          item.show = !item.show;
        }
      });
      if (type === 'edu') {
        this.form.education = list.filter(item => item.show);
      } else {
        this.form.competent = list.filter(item => item.show);
      }
    },
    sureAndPerfect (type) {
      if (type === 'sure') {
        this.editParams.perfectOperation = false;
      } else {
        this.editParams.perfectOperation = true;
      }
      // 注册地址
      const addArr = [
        { name: 'provName', code: 'provCode', opt: 'provOpt' },
        { name: 'cityName', code: 'cityCode', opt: 'cityOpt' },
        { name: 'areaName', code: 'areaCode', opt: 'areaOpt' },
        { name: 'streetName', code: 'streetCode', opt: 'streetOpt' }
      ];
      addArr.forEach(item => {
        const code = this.addressObj[item.code];
        this.doneBasicData[item.code] = code === '' ? null : code;
        const opt = this.addressObj[item.opt]
        if (opt.length > 0 && code !== '' && code !== null) {
          const optArr = opt.filter(o => o.code === code);
          if (optArr.length > 0) {
            this.doneBasicData[item.name] = optArr[0].name;
          } else {
            this.doneBasicData[item.name] = null;
          }
        } else {
          this.doneBasicData[item.name] = null;
        }
        // this.doneBasicData[item.name] = opt.length > 0 && code !== '' && code !== null ? opt.filter(o => o.code === code)[0].name : null
      });
      const { companyOrgType, enterpriseGroup, category, categoryBig, education, competent } = this.form;
      this.doneBasicData.education = education;
      this.doneBasicData.competent = competent;
      const intellPro = [];
      this.intellectualProperty.forEach(item => {
        const obj = {
          tagId: item.tagId,
          num: 0,
          tagName: item.tagName
        };
        if (item.num > 0) {
          obj.num = item.num;
        }
        intellPro.push(obj);
      });
      this.doneBasicData.intellectualProperty = intellPro;
      this.doneBasicData.businessProfile = this.businessProfile;
      if (category !== null && category !== '') {
        const categoryObj = this.categoryOpt.filter(item => item.tagId === category)[0];
        categoryObj.bigCategory = null;
        if (categoryBig !== null && categoryBig !== '') {
          categoryObj.bigCategory = this.categoryBigOpt.filter(item => item.tagId === categoryBig)[0];
        }
        this.doneBasicData.category = categoryObj;
      } else {
        this.doneBasicData.category = null;
      }
      // if (companyOrgType !== null && companyOrgType.length > 0) {
      //   const companyOrgTypeObj = this.companyOrgTypeOpt.filter(item => companyOrgType.includes(item.tagId))
      //   this.doneBasicData.companyOrgType = companyOrgTypeObj
      // }
      this.doneBasicData.companyOrgType = companyOrgType;
      if (enterpriseGroup !== null && enterpriseGroup !== '') {
        const enterpriseGroupObj = this.enterpriseGroupOpt.filter(item => item.tagId === enterpriseGroup)[0];
        this.doneBasicData.enterpriseGroup = enterpriseGroupObj;
      }
      this.doneBasicData.certification = this.certification;
      this.editParams.doneBasicData = { ...this.doneBasicData };
      getMatch({
        ...this.editParams
      }).then(res => {
        this.setBasicDatas(res.data.data);
        if (type === 'sure') {
          this.$emit('close', type);
        } else {
          this.pushTagGroupLists(res.data.data.tagGroupList);
          this.pushProgressBar(res.data.data.progressBar);
        }
      }).then(() => {
        setTimeout(() => {
          this.$emit('goToPerfect');
        }, 100);
      });
    },
    /* 参考标准 */
    handleStandard () {
      window.open('http://cdn.po-o.cn/2022/07/25/d33ec80cada94c40b010d9214650d377.pdf', '_blank');
    },
    resetLabel () {
      const h = this.$createElement;
      this.$msgbox({
        title: '',
        message: h('p', null, [
          h('span', { style: 'font-weight: bold; font-size: 16px;' }, '确认要还原默认标签吗？'),
          h('br'),
          h('span', { style: 'color: rgba(0, 0, 0, 0.8); font-size: 14px;' }, '此操作会将您修改过的所有信息还原')
        ]),
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        this.editParams.restoreOperation = true;
        getMatch({
          ...this.editParams
        }).then(res => {
          this.setBasicDatas(res.data.data);
          this.$emit('close', 'reset');
        });
      }).catch(() => {
        this.$emit('close');
      });
    }
  }
};
</script>

<style>
.autocomplete_input_popper_class {
  width: auto !important;
}
</style>

<style scoped lang="less">
.edit_data {
  .edit_box {
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    .box_title {
      color: rgba(0, 0, 0, 1);
      font-size: 16px;
      border-left: 4px solid #000;
      padding-left: 5px;
      .box_title_txt {
        margin-right: 20px;
      }
      .box_title_btn {
        .certification_input {
          margin-right: 20px;
        }
      }
    }
    .box_title_supply {
      margin-top: 25px;
    }
    .box_title_flex {
      display: flex;
      align-items: center;
    }
    .box_content_flex {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .box_content {
      margin-top: 20px;
      padding: 0 22px 8px 22px;
      .content_item {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        margin-right: 66px;
        .item_title {
          color: rgba(97, 119, 154, 1);
          font-size: 14px;
          margin-right: 14px;
        }
        .intellectual_box {
          width: 90px;
        }
        .item_select {}
      }
      .cert_active_item {
        padding: 10px 20px;
        background-color: rgba(0, 0, 0, 0.2);
      }
      .certification_item {
        border-radius: 3px;
        font-size: 14px;
        margin-right: 20px;
        margin-bottom: 27px;
        position: relative;
        .item_title {
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
        }
        .certification_item_icon {
          cursor: pointer;
          padding: 1px;
          width: 16px;
          height: 16px;
          font-size: 14px;
          text-align: center;
          background-color: rgba(0, 0, 0, 0.5);
          // color: rgba(0, 0, 0, 0.2);
          color: #fff;
          border-radius: 50%;
          position: absolute;
          top: -7px;
          left: 95%;
        }
      }
      .label_title {
        color: rgba(97, 119, 154, 1);
        font-size: 14px;
        margin-bottom: 10px;
      }
      .select_flex {
        display: flex;
      }
      .select_group {
        display: flex;
        align-items: center;
      }
      .label_address {
        display: flex;
        justify-content: flex-start;
        .label_address_select {
          margin-right: 20px;
        }
      }
      .label_select {
        margin-bottom: 27px;
        .select_group_img {
          cursor: pointer;
          color: rgba(255, 121, 2, 0.89);
          margin-left: 20px;
          display: flex;
          align-items: center;
          .select_group_img_question {
            width: 14px;
            height: 14px;
            background-image: url("../../../../../assets/pc/images/simpleMatching/consult.png");
            background-size: 14px 14px;
          }
        }
        .select_one {
          margin-right: 15px;
        }
        .radio {
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 14px;
          text-align: center;
          font-family: Roboto;
          margin-right: 10px;
        }
        .radio_active {
          background-color: rgba(235, 243, 255, 1);
          color: rgba(48, 120, 240, 1);
          border: 1px solid rgba(48, 120, 240, 1);
        }
        .radio_inactive {
          background-color: rgba(0, 0, 0, 0);
          color: rgba(0, 0, 0, 0.5);
          border: 1px solid rgba(187, 187, 187, 1);
        }
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer_left {
      cursor: pointer;
      color: rgba(65, 96, 146, 0.6);
      font-size: 14px;
    }
    .footer_right {
      display: flex;
      .item_btn {
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        text-align: center;
        font-family: Roboto;
        padding: 15px 30px;
        font-weight: bold;
      }
      .item_btn:hover {
        opacity: 0.7;
      }
      .left_btn_active {
        border: 1px solid rgba(48, 120, 240, 1);
        background-color: #fff;
        color: rgba(48, 120, 240, 1);
        margin-right: 12px;
      }
      .right_btn {
        border: 1px solid rgba(48, 120, 240, 1);
        color: #fff;
        background-color: rgba(48, 120, 240, 1);
      }
    }
  }
}
</style>
